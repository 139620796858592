import axios from "axios";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../api/api";
import ReactPaginate from "react-paginate";

const ManageOffers = () => {
  const [data, setData] = useState([]);
  const [id, setId] = useState("");
  const [version, setVersion] = useState("");
  const [showUpdate, setShowUpdate] = useState(false);
  const toggleUpdate = () => setShowUpdate(!showUpdate);

  const [searchValue, setSearchValue] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 5;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(data?.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  useEffect(() => {
    axios
      .get(`${BASE_URL}/rent/create-documents-from-url/`)
      .then((response) => {
        setData(response.data);
      })
      .then(
        (response) => {},
        (err) => {
          console.log("No Data To Show");
        }
      )
      .catch((err) => {
        return false;
      });
  }, []);
  console.log(data, "your data");
  const updateVersion = (e) => {
    e.preventDefault(e);
    axios
      .patch(`${BASE_URL}/rent/update-document/${id}/`, {
        version: version,
      })
      .then(
        (response) => {
          alert("Offer Version Updated as Successfully");
          window.location.reload();
        },
        (err) => {
          alert("failed");
        }
      );
  };
  const deleteVersion = (e) => {
    e.preventDefault(e);
    axios.delete(`${BASE_URL}/rent/delete-document/${id}/`).then(
      (response) => {
        alert("Offer Version deleted as Successfully");
        window.location.reload();
      },
      (err) => {
        alert("failed");
      }
    );
  };
  return (
    <div className="flex justify-center flex-col space-y-4 items-center mt-2 ">
      <input
        type="text"
        id="table-search"
        class="w-64 flex-1 bg-gray-50 border border-blue-100 focus:ring-0 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  pl-10 p-2.5    "
        placeholder="Search for document status"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
      />
      <table class="table-fixed border-collapse   border border-slate-400">
        <thead>
          <tr>
            <th className="border border-slate-300">Bunnummer</th>
            <th className="border border-slate-300">Dossier name</th>
            <th className="border border-slate-300">Client Name</th>
            <th className="border border-slate-300">Document Creation Date</th>
            <th className="border border-slate-300">Document Status</th>
            <th className="border border-slate-300">Version</th>
            <th className="border border-slate-300">
              Manage <br></br>Offer Version
            </th>
          </tr>
        </thead>
        <tbody>
          {data
            ?.filter((row) => row?.status?.match(new RegExp(searchValue, "i")))
            ?.slice(pagesVisited, pagesVisited + usersPerPage)
            .map((items) => {
              return (
                <tr key={items.id}>
                  <td className="border border-slate-300">
                    {items?.rental_bon_number}{" "}
                  </td>
                  <td className="border border-slate-300">{items?.dossier} </td>
                  <td className="border border-slate-300">
                    {items?.client_username}
                  </td>
                  <td className="border border-slate-300">
                    {" "}
                    {items?.created_at}
                  </td>

                  <td className="border border-slate-300">{items?.status}</td>
                  <td className="border border-slate-300">{items.version}</td>
                  <td
                    // onClick={() => {
                    //   setId(items.id);
                    //   setVersion(items.version);
                    //   toggleUpdate();
                    // }}
                    className="border border-slate-300 text-blue-500 cursor-pointer"
                  >
                    <div className="flex space-x-6">
                      <svg
                        onClick={() => {
                          setId(items.id);
                          setVersion(items.version);
                          toggleUpdate();
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                        />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                        onClick={(e) => deleteVersion(e)}
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                        />
                      </svg>
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      <div className="mt-10">
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"paginationBttns"}
          previousLinkClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
        />
      </div>
      {showUpdate && (
        <div
          id="authentication-modal"
          tabindex="-1"
          aria-hidden="true"
          className="bg-gray-500 flex justify-center items-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full"
        >
          <div class="relative p-4 w-full max-w-md h-full md:h-auto">
            <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
              <button
                type="button"
                class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                data-modal-toggle="authentication-modal"
              >
                <svg
                  onClick={() => toggleUpdate()}
                  class="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </button>
              <div class="py-6 px-6 lg:px-8">
                <h3 class="mb-4 text-xl font-medium text-gray-900 dark:text-white">
                  Update
                </h3>
                <div className="flex space-x-10 justify-around items-center">
                  <form class="space-y-6" action="#">
                    <div>
                      <label
                        for="email"
                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        Bon Number
                      </label>
                      <input
                        type="text"
                        name="description"
                        id="desc"
                        value={id}
                        onChange={(e) => setId(e.target.value)}
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                        required
                      />
                    </div>
                    <div>
                      <label
                        for="email"
                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        Version
                      </label>
                      <input
                        type="text"
                        name="description"
                        id="desc"
                        value={version}
                        onChange={(e) => setVersion(e.target.value)}
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                        required
                      />
                    </div>

                    <button
                      onClick={(e) => updateVersion(e)}
                      class="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                      Update
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ManageOffers;
