const urls = {
  signup: "Users",
  login: "/accounts/get-token/",
  resetPassword: "Users/resetpassword/:",
  addCompany: "Company",
  updateUser: "Users",
  updateCompany: "auth/updateCompany",
  changePassword: "Users/changePassword",
  addCompanyService: "CompanyService",
};

export default urls;
