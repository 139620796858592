import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <div className="bg-sky-600 text-white">
      <div className="flex justify-around items-center p-4">
        <div className="flex space-x-4 text-xl">
          <Link to="/">Create Offer and Order</Link>
        </div>{" "}
        <div className="flex space-x-4 text-xl">
          <div>
            <Link to="/manage-offers">Status Paperless Offers</Link>
          </div>
          <div>
            <Link to="/archives">Archive</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
