import { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Redirect } from "react-router-dom";
import apiClient from "../auth/resources/apiClient";
import http from "./resources/http";
import { set_user_status, set_user_data, set_token } from "./store/actions";

const Login = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();

  const submit = async (e) => {
    e.preventDefault();
    if (email === "") {
      alert("Please enter email");
    } else if (password === "") {
      alert("Please enter password");
    } else if (email.length < 4 || password.length < 5) {
      alert("Email length should be atleast six character");
    } else {
      try {
        // http
        //   .post("https://abel.keseevents.com/accounts/get-token/", {
        //     username: email,
        //     password: password,
        //   })
        const response = await apiClient.auth
          .login({
            username: email,
            password: password,
            // username: "helghsdfdlo",
            // password: "sdsjfghbdcjh",
          })
          .then(
            (response) => {
              props.set_token(response.data.access);
              props.set_user_data(response.data);
              props.set_user_status({
                approved: response.data.approved,
                loggedIn: true,
                token: response.data.access,
              });
              return history.push("/offer");
            },
            (err) => {
              alert("Email and password is not valid");
            }
          );
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="px-8 py-6 mt-4 text-left bg-white shadow-lg">
        <h3 className="text-2xl font-bold text-center">
          Login to your account
        </h3>
        <form action="">
          <div className="mt-4">
            <div>
              <label className="block" for="email">
                Email
              </label>
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="text"
                placeholder="Email"
                className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"
              />
            </div>
            <div className="mt-4">
              <label className="block">Password</label>
              <input
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                required
                type="password"
                placeholder="Password"
                className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"
              />
            </div>
            <div className="flex items-baseline justify-between">
              <button
                onClick={(e) => {
                  submit(e);
                }}
                className="px-6 py-2 mt-4 bg-yellow-600 text-white  rounded-lg "
              >
                Login
              </button>
              <a
                href="reset-password.com"
                className="text-sm text-blue-600 hover:underline"
              >
                Forgot password?
              </a>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default connect(null, { set_token, set_user_status, set_user_data })(
  Login
);
