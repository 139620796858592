import axios from "axios";

const http = axios.create({
  baseURL: `https://quadronics-backend.herokuapp.com/`,
  Headers: {},
});

try {
  http.interceptors.request.use(
    (config) => {
      let data = JSON.parse(localStorage.getItem("desta"));

      if (data && data.token) {
        // config.headers["Authorization"] = "Bearer " + data.token;
      }

      return config;
    },

    (error) => {
      return Promise.reject(error);
    }
  );
} catch (error) {
  console.log(error);
}

export default http;
