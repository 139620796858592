import React, { useEffect, useRef, useState } from "react";
import SignaturePad from "react-signature-canvas";
import { Document, Page } from "react-pdf";
// Import the main component
import { Viewer } from "@react-pdf-viewer/core"; // install this library
// Plugins
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout"; // install this library
// Import the styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
// Worker
import { Worker } from "@react-pdf-viewer/core"; // install this library
import AES from "crypto-js/aes";
import { enc } from "crypto-js";
import axios from "axios";
import { Link, useHistory, useParams } from "react-router-dom";
import http from "../auth/resources/http";
import ConfirmSign from "./ConfirmSign";
const Signature = (props) => {
  const history = useHistory();
  //
  const [file, setFile] = useState(null);
  const [email, setEmail] = useState("");
  const handleFile = (e) => {
    let file = e.target.files[0];
    setFile(file);
  };
  //
  const { id } = useParams();
  const [ip, setIp] = useState();
  const baseURL =
    "https://api.quadronics.adeymeselesh.de/rent/doc-detail/" + id;
  const [datas, setData] = useState([]);
  const [imgg, setImg] = useState("");
  const [accepted, setAccepted] = useState(true);
  const [name, setName] = useState("");
  const [addCompany, setAddCompany] = useState(false);
  const toggleAddCompany = () => setAddCompany(!addCompany);
  let signPad = useRef({});
  var encryptedIp = AES.encrypt(ip, "secret key 123").toString();
  var bytes = AES.decrypt(encryptedIp, "secret key 123");
  var originalText = bytes.toString(enc.Utf8);

  let data = "";
  const clear = () => {
    signPad.current.clear();
  };
  const save = () => {
    data = signPad.current.toDataURL();
  };
  const show = () => {
    signPad.current.fromDataURL(data);
  };
  const trim = () => {
    var trimmedDataURL = signPad.current
      .getTrimmedCanvas()
      .toDataURL("image/png");
    setImg(trimmedDataURL);
  };
  const [checked, setChecked] = useState(false);
  const [checkedDDPR, setCheckedDPPR] = useState(false);
  const handleChangeCheckBox = () => {
    setChecked(!checked);
    trim();
  };
  const handleChangeCheckBoxGDPR = () => {
    setCheckedDPPR(!checkedDDPR);
    trim();
  };

  const handleUploadFile = () => {
    axios
      .post(`https://api.quadronics.adeymeselesh.de/rent/create-offers/`, {
        signature: imgg,
        name: name,
        email: email,
        ip: encryptedIp,
        comment: "This is test comment",
        accepted: accepted,
        rejected: false,
        document_id: id,
        invitation: null,
      })
      .then(
        (response) => {
          alert("Document Signed as Successfully");
        },
        (err) => {
          alert("failed");
        }
      );
  };
  const rejectedDoc = () => {
    axios
      .patch(
        `https://api.quadronics.adeymeselesh.de/rent/update-document/${id}/`,
        {
          status: "rejected",
        }
      )
      .then(
        (response) => {
          alert("Offer Rejected as Successfully");
        },
        (err) => {
          alert("failed");
        }
      );
  };
  const acceptedDoc = () => {
    axios
      .patch(
        `https://api.quadronics.adeymeselesh.de/rent/update-document/${id}/`,
        {
          status: "accepted",
        }
      )
      .then(
        (response) => {
          console.log("Offer Accepted as Successfully");
        },
        (err) => {
          alert("failed");
        }
      );
  };

  useEffect(() => {
    axios
      .get(baseURL)
      .then((response) => {
        setData(response.data);
        setEmail(response.data.client_email);
        setName(response.data.client_username);
      })
      .then(
        (response) => {},
        (err) => {
          console.log("No Data To Show");
        }
      )
      .catch((err) => {
        return false;
      });
  }, []);

  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    console.log(res.data);
    setIp(res.data.IPv4);
  };
  useEffect(() => {
    getData();
  }, []);
  // Create new plugin instance
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  console.log("your infos", datas);
  return (
    <div className="p-10 flex flex-col space-y-10 font-serif justify-center items-center">
      <img src={imgg} />

      {datas?.pdf && (
        <>
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
            <div className="w-full h-[50rem]">
              <Viewer
                fileUrl={datas?.pdf}
                plugins={[defaultLayoutPluginInstance]}
              />
            </div>
          </Worker>
        </>
      )}
      <div>
        Your Name:{" "}
        <input
          value={name}
          onChange={(e) => setName(datas.client_username)}
          type="text"
          className="border border-gray-300 outline-none p-1 w-96"
        />
      </div>
      <div>
        Your Email:{" "}
        <input
          value={email}
          onChange={(e) => setEmail(datas.client_email)}
          type="text"
          className="border border-gray-300 outline-none p-1 w-96"
        />
      </div>
      <div className="  w-56 mt-10   ">
        <p className="flex justify-start items-start -mx-36">Your Signature</p>
        <div className="flex justify-center items-center">
          <SignaturePad
            canvasProps={{
              className: "sigCanvas w-full h-full",
            }}
            className="border-2 border-black"
            ref={signPad}
          />
        </div>
      </div>
      <div className=" space-x-4 -mt-24 items-center">
        <button onClick={clear}>Clear</button>
      </div>
      <div className="flex space-x-4 -mt-24 items-center">
        {" "}
        <input
          className=""
          type="checkbox"
          onChange={handleChangeCheckBoxGDPR}
        />{" "}
        <p>Accept GDPR rules and regulations</p>
      </div>
      <div className="flex space-x-4 -mt-24 items-center">
        {" "}
        <input
          className=""
          type="checkbox"
          onChange={handleChangeCheckBox}
        />{" "}
        <p>
          Accept terms and services{" "}
          <a className="text-blue-500" href="#">
            read here
          </a>
        </p>
      </div>

      <div className="flex space-x-4">
        {checkedDDPR && (
          <Link
            className="bg-gray-500 text-center text-white font-bold px-2 py-1"
            to={`/confirm/sign/${id}`}
          >
            <button
              onClick={() => {
                trim();
                acceptedDoc();
                handleUploadFile();
              }}
              className="bg-gray-500 rounded-sm px-2 text-white"
            >
              Accept Offer
            </button>
          </Link>
        )}

        <button
          onClick={() => rejectedDoc()}
          className="bg-red-500 rounded-sm px-2 text-white"
        >
          Reject Offer
        </button>
      </div>
    </div>
  );
};

export default Signature;
