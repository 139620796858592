import logo from "./logo.svg";
import "./App.css";
import Signature from "./components/Signature";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import Header from "./components/Header";
import Invitation from "./components/Invitation";
import Login from "./auth/Login";
import Signup from "./auth/Signup";
import ConfirmSign from "./components/ConfirmSign";
import Archives from "./components/Archives";
import ManageOffers from "./components/ManageOffers";

function App() {
  return (
    <>
      <Router>
        <Route exact path="/">
          <Header />
          <Invitation className="bg-gray-100" />
        </Route>
        <Route exact path="/manage-offers">
          <Header />
          <ManageOffers className="bg-gray-100" />
        </Route>
        <Route exact path="/login">
          <Header />
          <Login className="bg-gray-100" />
        </Route>
        <Route exact path="/archives">
          <Header />
          <Archives />{" "}
        </Route>
        <Route exact path="/signup">
          <Signup className="bg-gray-100" />
        </Route>
        <Switch>
          <Route exact path="/confirm">
            <Header />
            <ConfirmSign className="bg-gray-100" />
          </Route>
          <Route exact path="/signature">
            <Header />
            <Signature className="bg-gray-100" />
          </Route>
          <Route exact path={["/rent/sign", "/rent/sign/:id"]}>
            <Signature />
          </Route>
          <Route exact path={["/confirm/sign", "/confirm/sign/:id"]}>
            <ConfirmSign />
          </Route>
        </Switch>
      </Router>
    </>
  );
}

export default App;
