import http from "./http";
import urls from "./urls";

const apiClient = {
  auth: {
    signup(payload) {
      return http.post(urls.signup, payload);
    },
    login(payload) {
      return http.post(urls.login, payload);
    },
    addCompany(payload) {
      return http.post(urls.addCompany, payload);
    },
    updateUser(payload) {
      return http.put(urls.updateUser, payload);
    },
    changePassword(payload) {
      return http.put(urls.changePassword, payload);
    },
    resetPassword(payload) {
      return http.post(urls.resetPassword, payload);
    },
    updateCompany(payload) {
      return http.post(urls.updateCompany, payload);
    },
    addCompanyService(payload) {
      return http.post(urls.addCompanyService, payload);
    },
  },
};

export default apiClient;
