import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { BASE_URL } from "../api/api";

const Invitation = () => {
  const [file, setFile] = useState(null);
  const [data, setData] = useState([]);
  const [email, setEmail] = useState("");
  const [to, setTo] = useState("");
  const [docId, setDocId] = useState("");
  const [bonNumber, setBonNumber] = useState("");
  const [dossier, setDossier] = useState("");
  const [company, setCompany] = useState("");
  const [companyUserName, setCompanyUserName] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [clientUserName, setClientUserName] = useState("");
  const [language, setLanguage] = useState("");
  const [messege, setMessege] = useState("");
  const [version, setVersion] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endtDate, setEndDate] = useState("");
  const form = useRef();
  const sendEmail = (docId) => {
    axios
      .post("https://aem.adeymeselesh.de/api/v1/organization/sendLink", {
        link: `https://quadronics.adeymeselesh.de/rent/sign/${docId}`,
        from: "dev@adeymailserver.de",
        to: clientEmail,
        cc: email,
        bcc: email,
        subject: "Welcome DigiSign",
        text: "Please open the document and sign the contract",
      })
      .then(
        (response) => {
          alert("Offer sent Successfully to", clientEmail);
        },
        (err) => {
          alert("failed");
        }
      );
  };

  const handleFile = (e) => {
    let file = e.target.files[0];
    setFile(file);
  };
  const handleUploadFile = () => {
    var start = moment(startDate);
    var end = moment(endtDate);
    if (start.isAfter(end)) {
      alert("Start Date should be greater than End Date");
    } else {
      let formData = new FormData();
      formData.append("initial_pdf", file);
      formData.append("rental_bon_number", bonNumber);
      formData.append("client_email", clientEmail);
      formData.append("dossier", dossier);
      formData.append("company", company);
      formData.append("company_username", companyUserName);
      formData.append("user_email", email);
      formData.append("language", language);
      formData.append("message", messege);
      formData.append("start_date", startDate);
      formData.append("end_date", endtDate);
      formData.append("version", version);
      formData.append("client_username", clientUserName);
      axios(`${BASE_URL}/rent/create-documents-from-url/`, {
        method: "POST",
        mode: "cors",
        data: formData,
      }).then(
        (response) => {
          setDocId(response.data.id);
          sendEmail(response.data.id);
          alert("Success");
        },
        (response) => {
          alert(response.message);
        }
      );
    }
  };

  return (
    <div className="p-10  space-x-4 flex-col space-y-4 justify-center items-center">
      <div className="flex flex-col space-y-2 justify-center items-center">
        <label>Upload PDF File</label>
        <input
          className=" w-64"
          type="file"
          name="file"
          onChange={(e) => {
            handleFile(e);
          }}
        />
      </div>
      <input
        className="border p-2 focus:outline-none"
        type="text"
        placeholder=" Rental Bon Number"
        value={bonNumber}
        onChange={(e) => setBonNumber(e.target.value)}
      />
      <input
        className="border p-2 focus:outline-none"
        type="text"
        placeholder="Dossier"
        value={dossier}
        onChange={(e) => setDossier(e.target.value)}
      />
      <input
        className="border p-2"
        type="text"
        value={company}
        placeholder=" Company Name"
        onChange={(e) => setCompany(e.target.value)}
      />
      <input
        className="border p-2 focus:outline-none"
        type="text"
        placeholder="Company User Name"
        value={companyUserName}
        onChange={(e) => setCompanyUserName(e.target.value)}
      />
      <input
        className="border p-2 focus:outline-none"
        type="email"
        value={email}
        placeholder="Your Email"
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        className="border p-2 focus:outline-none"
        type="email"
        value={clientEmail}
        placeholder="Client Email"
        onChange={(e) => setClientEmail(e.target.value)}
      />
      <input
        className="border p-2 focus:outline-none"
        type="text"
        placeholder="Client User Name"
        value={clientUserName}
        onChange={(e) => setClientUserName(e.target.value)}
      />
      <input
        className="border p-2 focus:outline-none"
        type="text"
        placeholder="Language"
        value={language}
        onChange={(e) => setLanguage(e.target.value)}
      />
      <input
        className="border p-2 focus:outline-none"
        type="text"
        placeholder="Version"
        value={version}
        onChange={(e) => setVersion(e.target.value)}
      />
      <input
        className="border p-2 focus:outline-none"
        type="textarea"
        placeholder="Message"
        value={messege}
        onChange={(e) => setMessege(e.target.value)}
      />
      <div className="flex space-x-10">
        <div>
          <p>Start Date</p>
          <input
            className="border p-2"
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </div>
        <div>
          <p>End Date</p>
          <input
            className="border p-2"
            type="date"
            value={endtDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>
      </div>
      <button
        className="bg-yellow-600 px-3 py-2"
        onClick={() => handleUploadFile()}
      >
        Submit
      </button>
      {/* <div className="flex flex-col space-y-6 w-full">
        {data.map((items) => {
          return (
            <div key={items.id}>
              <div className="p-2 flex justify-around items-c ">
                <form
                  className="flex flex-col space-y-2 pb-4"
                  onSubmit={sendEmail}
                >
                  <input
                    className=" hidden border p-2"
                    type="text"
                    value="jdfjk"
                    name="user_name"
                  />

                  <input
                    className="border hidden p-2"
                    type="email"
                    value={items.client_email}
                    
                  />
                  <input
                    className="hidden"
                    value={items.user_email}
                    type="email"
                    name="user_email"
                  />
                  <input
                    className="hidden"
                    type="text"
                    value={`http://localhost:3000/api/rent/sign/${items.id}`}
                    name="offer_link"
                  />
                  <input
                    className="hidden border p-2"
                    type="text"
                    name="user_name"
                  />
                  <input
                    className="bg-yellow-600 text-white py-2 px-3 cursor-pointer"
                    type="submit"
                    value="Send"
                  />
                </form>
              </div>
            </div>
          );
        })}
      </div> */}
    </div>
  );
};

export default Invitation;
