import axios from "axios";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
const Signup = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [name, setName] = useState("");
  const history = useHistory();
  const signup = () => {
    axios
      .post("https://quadronics-backend.herokuapp.com/accounts/signup/", {
        password: password,
        name: name,
        username: username,
        email: email,
      })
      .then(() => {
        history.push("/");
      })
      .catch((err) => {
        alert("failed");
      });
  };
  return (
    <div className="flex justify-center items-center mt-10 ">
      <div className="flex border p-10 flex-col space-y-10 justify-center items-center mt-10">
        <p className="text-yellow-600 font-bold text-xl">
          Signup your RentPlus account
        </p>
        <div>
          <input
            className="p-2 outline-none focus:ring-0 border border-yellow-600"
            placeholder="Enter Name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div>
          <input
            className="p-2 outline-none focus:ring-0 border border-yellow-600"
            placeholder="Enter Username"
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div>
          <input
            className="p-2 outline-none focus:ring-0 border border-yellow-600"
            placeholder="Enter email"
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div>
          <input
            className="p-2 outline-none focus:ring-0 border border-yellow-600"
            placeholder="Enter Password"
            type="text"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        <div>
          <button
            onClick={signup}
            className="bg-yellow-600 px-3 p-1 w-36 text-white text-bold text-xl"
          >
            Signup
          </button>
        </div>
      </div>
    </div>
  );
};

export default Signup;
