import axios from "axios";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../api/api";
import ReactPaginate from "react-paginate";

const Archives = () => {
  const [data, setData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 5;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(data?.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  useEffect(() => {
    axios
      .get(`${BASE_URL}/rent/list-offers/`)
      .then((response) => {
        setData(response.data);
      })
      .then(
        (response) => {},
        (err) => {
          console.log("No Data To Show");
        }
      )
      .catch((err) => {
        return false;
      });
  }, []);
  console.log(data, "your data");
  return (
    <div className="flex justify-center flex-col space-y-4 items-center mt-2 ">
      <div className="flex space-x-24 items-center">
        <input
          type="text"
          id="table-search"
          class="w-64 flex-1 bg-gray-50 border border-blue-100 focus:ring-0 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  pl-10 p-2.5    "
          placeholder="Search for Bon Number"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <div className="flex space-x-24 justify-around border p-2">
          <div className="bg-blue-100 w-64 flex-1 text-center">All</div>
          <div className="bg-blue-500 text-white flex-1 text-center">
            Active
          </div>
        </div>
      </div>
      <table class="table-fixed border-collapse   border border-slate-400">
        <thead>
          <tr>
            <th className="border border-slate-300">Bunnummer</th>
            <th className="border border-slate-300">Dossier name</th>
            <th className="border border-slate-300">Client Name</th>
            <th className="border border-slate-300">Document Type</th>
            <th className="border border-slate-300">Document Creation Date</th>
            <th className="border border-slate-300">Start Date</th>
            <th className="border border-slate-300">End Date</th>
            <th className="border border-slate-300">Document Status</th>
            <th className="border border-slate-300">Signing Date</th>
            <th className="border border-slate-300">Document</th>
            <th className="border border-slate-300">Last Action Date</th>
          </tr>
        </thead>
        <tbody>
          {data
            ?.filter((row) =>
              row?.document.rental_bon_number
                ?.match(new RegExp(searchValue, "i"))
                ?.slice(pagesVisited, pagesVisited + usersPerPage)
            )
            .map((items) => {
              return (
                <tr>
                  <td className="border border-slate-300">
                    {items?.document?.rental_bon_number}{" "}
                  </td>
                  <td className="border border-slate-300">
                    {items?.document?.dossier}{" "}
                  </td>
                  <td className="border border-slate-300">
                    {items?.document?.client_username}
                  </td>
                  <td className="border border-slate-300">Offer</td>
                  <td className="border border-slate-300">
                    {" "}
                    {items?.document?.created_at}
                  </td>
                  <td className="border border-slate-300">25-9-2022</td>
                  <td className="border border-slate-300">25-12-2022</td>
                  <td className="border border-slate-300">signed</td>
                  <td className="border border-slate-300">
                    {items?.created_at}
                  </td>
                  <td className="border border-slate-300">
                    <a
                      className="text-blue-400"
                      target="_blank"
                      href={items?.signed_document}
                    >
                      Click to view
                    </a>
                  </td>
                  <td className="border border-slate-300">
                    {items?.created_at}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      <div className="mt-10">
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"paginationBttns"}
          previousLinkClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
        />
      </div>
    </div>
  );
};

export default Archives;
