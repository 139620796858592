import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import emailjs from "emailjs-com";
import http from "../auth/resources/http";
// Import the main component
import { Viewer } from "@react-pdf-viewer/core"; // install this library
// Plugins
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout"; // install this library
// Import the styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
// Worker
import { Worker } from "@react-pdf-viewer/core";
import { BASE_URL } from "../api/api";

const ConfirmSign = ({ userEmail }) => {
  const [datas, setDatas] = useState([]);
  const [from, setFrom] = useState("");
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [to, setTo] = useState("");

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/rent/create-offers/`)
      .then((response) => {
        setData(response.data);
      })
      .then(
        (response) => {},
        (err) => {
          console.log("No Data To Show");
        }
      )
      .catch((err) => {
        return false;
      });
  }, []);
  useEffect(() => {
    axios
      .get(`${BASE_URL}/rent/doc-detail/${id}/`)
      .then((response) => {
        setDatas(response.data);
        setTo(response?.data?.user_email);
        setFrom(response?.data?.client_email);
      })
      .then(
        (response) => {},
        (err) => {
          console.log("No Data To Show");
        }
      )
      .catch((err) => {
        return false;
      });
  }, []);
  const sendEmail = (e, doc) => {
    e.preventDefault();
    axios
      .post("https://aem.adeymeselesh.de/api/v1/organization/sendEmail", {
        attachement: doc,
        from: "dev@adeymailserver.de",
        to: to,
        cc: from,
        bcc: from,
        subject: "Welcome DigiSign",
        text: "Here is your signed document",
      })
      .then(
        (response) => {
          alert("Offer Signed and sent Successfully");
        },
        (err) => {
          alert("failed");
        }
      );
  };
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  console.log("hello", datas);
  return (
    <div className="flex justify-center flex-col items-center mt-10">
      <p className="text-3xl">
        {" "}
        Preview Signed Document and Corfirm Your Signing{" "}
      </p>
      <div>
        {data
          ?.filter((doc) => doc?.document?.id === id)
          ?.map((items) => {
            return (
              <div>
                <div className="flex flex-around items-center space-x-4 pb-10 pt-10"></div>
                <form
                  className="flex flex-col space-y-2 pb-4 w-full"
                  onSubmit={(e) => sendEmail(e, items.signed_document)}
                >
                  <label className="hidden">Recipient Email</label>
                  <input
                    className="border p-2 hidden"
                    type="email"
                    value={datas.user_email}
                    name="client_email"
                  />

                  <input
                    className="hidden"
                    type="text"
                    value={items.signed_document}
                    name="offer_link"
                  />
                  {datas?.pdf && (
                    <>
                      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                        <div className="w-[60rem] h-[50rem]">
                          <Viewer
                            fileUrl={items?.signed_document}
                            plugins={[defaultLayoutPluginInstance]}
                          />
                        </div>
                      </Worker>
                    </>
                  )}
                  <div className="flex justify-around space-x-16 text-center">
                    <Link
                      to={`/rent/sign/${id}`}
                      className="w-full bg-blue-600 text-white py-2 text-center flex justify-center items-center"
                    >
                      Sign Again
                    </Link>

                    <input
                      className="w-full bg-yellow-600 text-white py-2"
                      type="submit"
                      value="Confirm"
                    />
                  </div>
                </form>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default ConfirmSign;
